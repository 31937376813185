import React, { useState, useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import xml2js from 'xml2js';
import { NextSeo } from 'next-seo';
import cx from 'classnames';
import { addApolloState, initializeApollo } from '@/hooks/apolloClient';
import { ThemeGeneralSettingsDocument, useGetAllStaffImagesQuery } from '../generated/graphql';
import useOutsideClick from '@/hooks/useOutsideClick';

const Header = dynamic(() => import('@/components/Header'));
const PageHeader = dynamic(() => import('@/components/PageHeader'));
const GlobalAppThemeProvider = dynamic(() => import('@/components/GlobalAppTheme'));
const Container = dynamic(() => import('@/components/Container'));
const Footer = dynamic(() => import('@/components/Footer/Footer'));
const ClickHandler = dynamic(() => import('@/components/ClickHandler'));
const DateTime = dynamic(() => import('@/components/AttendEvents/DateTime'));
const NoResults = dynamic(() => import('@/components/AttendEvents/NoResults'));

export default function AttendEvents() {
    const currentDate = new Date();

    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(1);
    const [zipCode, setZipCode] = useState('');
    const [attendEventsData, setAttendEventsData] = useState([]);
    const [dateDropdownShown, setdateDropdownShown] = useState(false);
    const [startandEndDate, setStartandEndDate] = useState([currentDate.toLocaleDateString(), '']);
    const [monthSelected, setMonthSelected] = useState('');
    const [attendEventsLoading, setAttendEventsLoading] = useState(true);
    const startDate = startandEndDate?.[0];
    const prevKeyword = useRef(keyword);
    const prevZipCode = useRef(zipCode);
    const prevStartDate = useRef(startDate);

    const { data: staffData, loading, error } = useGetAllStaffImagesQuery();

    const monthArray2 = [];
    for (let i = 0; i <= 4; i++) {
        const currentMonth = currentDate.getMonth();
        let nextMonth = currentMonth + i;
        const currentYear = currentDate.getFullYear();
        let nextYear = currentYear;
        if (nextMonth > 11) {
            nextMonth = nextMonth - 12;
            nextYear += 1;
        }
        const newDate = new Date(nextYear, nextMonth, 1);
        monthArray2.push(newDate.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' }));
    }

    // useEffect(() => {
    //     const body = document.querySelector('body');
    //     console.log('b', body);
    //     body.scrollIntoView({ behavior: 'instant', block: 'start' });
    // });

    const zipCodeFilter = zipCode ? `<MEETINGPOSTCODE>${zipCode}</MEETINGPOSTCODE>` : '';

    const requestPayload = `<?xml version="1.0" encoding="utf-8"?>
<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
        <GetRowsRequest xmlns="blackbaud_appfx_server_bizops">
            <Filter>
            <KEYWORDS>${keyword}</KEYWORDS>
            <PAGEINDEX>${page}</PAGEINDEX>
            <MEETINGCOUNTRY>United States</MEETINGCOUNTRY>
            <SDATE>${startDate}</SDATE>
            <EDATE>${startandEndDate?.[1]}</EDATE>
            <RADIUS>50</RADIUS>
            ${zipCodeFilter}
            </Filter>
        </GetRowsRequest>
    </soap12:Body>
</soap12:Envelope>`;

    const parseString = xml2js.parseString;
    const attendEventsRows = attendEventsData?.['Rows']?.[0]?.['ListRow'];

    function changeStartDate(monthName, monthDate, monthLastDay = '') {
        setMonthSelected(monthName);
        setStartandEndDate([monthDate.toLocaleDateString(), monthLastDay ? monthLastDay.toLocaleDateString() : '']);
    }

    useEffect(() => {
        const timeoutTime = keyword === prevKeyword.current ? 0 : 500;
        (keyword !== prevKeyword.current || zipCode !== prevZipCode.current || startDate !== prevStartDate.current) && setPage(1);
        const displayResults = setTimeout(() => {
            if (zipCode.length === 0 || zipCode.length === 5 || keyword !== prevKeyword.current) {
                setAttendEventsLoading(true);
                fetch('/api/fetchAttendEventsData', {
                    method: 'POST',
                    body: requestPayload
                })
                    .then(res => res.text())
                    .then(data => {
                        parseString(data, (err, result) => {
                            setAttendEventsData(result['soap:Envelope']['soap:Body'][0]['GetRowsReply'][0]);
                            setAttendEventsLoading(false);
                        });
                    });
            }
        }, timeoutTime);

        prevKeyword.current = keyword;
        prevZipCode.current = zipCode;
        prevStartDate.current = startDate;

        return () => clearTimeout(displayResults);
    }, [keyword, page, zipCode, startandEndDate]);

    const clickRef = useOutsideClick(() => setdateDropdownShown(false));

    return (
        <GlobalAppThemeProvider>
            <ClickHandler className="bg-darkGray dark:bg-darkModeBg dark:text-white">
                <div className="page-container">
                    <NextSeo title={'Attend Events - Jews for Jesus'} description={'Find a Jews for Jesus Event in the USA'} canonical={'/'} />
                    <Header />
                    <PageHeader
                        headerStyleChoices="basic"
                        className="mb-8 bg-darkGray dark:text-white"
                        type="page"
                        title="Events"
                        titleSubtitleTextColor="#333"
                        subtitle={'Find a Jews for Jesus Event in the USA'}
                        subtitleSize="p"
                        titleSubtitleTextAlignment="left"
                        showTitle></PageHeader>
                    <Container width="normal">
                        <h4 className="mb-6 text-center font-bold">Search Filters</h4>
                        <div className="mx-auto flex flex-col px-3 md:flex-row md:justify-center lg:w-1/2">
                            <div className="mx-auto mb-4 w-full max-w-[300px] md:mx-5 md:mb-8 md:w-[200px]">
                                <input
                                    value={zipCode}
                                    onChange={e => setZipCode(e.target.value)}
                                    placeholder="Zip Code"
                                    type="number"
                                    style={{ boxShadow: 'none' }}
                                    className="w-full max-w-[300px] whitespace-pre rounded-[5px] border-none bg-white py-4 pl-5 pr-3 text-left font-proxima font-normal text-black transition duration-500 ease-in-out dark:text-black md:w-[200px]"
                                    id="search-form"
                                />
                            </div>
                            <div className="mx-auto mb-4 w-full max-w-[300px] md:mx-5 md:mb-8 md:w-[300px]">
                                <input
                                    value={keyword}
                                    onChange={e => setKeyword(e.target.value)}
                                    placeholder="Speaker / Event / Church"
                                    type="search"
                                    className="w-full max-w-[300px] whitespace-pre rounded-[5px] border-none bg-white py-4 pl-5 pr-1 text-left font-proxima font-normal text-black transition duration-500 ease-in-out placeholder:pr-0 dark:text-black md:w-[300px]"
                                    id="search-form"
                                />
                            </div>
                            <div className="relative mx-auto mb-4 w-full max-w-[200px] px-5 text-center md:mb-8">
                                <button
                                    ref={clickRef}
                                    className={cx(
                                        'whitespace-pre rounded-full px-8 py-4 font-proxima font-normal text-white transition duration-500 ease-in-out hover:bg-lightBlue',
                                        dateDropdownShown || monthSelected ? 'bg-lightBlue' : 'bg-[#999]'
                                    )}
                                    onClick={() => setdateDropdownShown(prevState => !prevState)}>
                                    {monthSelected || 'Month'}
                                </button>
                                {dateDropdownShown && (
                                    <div className={cx('absolute right-[-3.5rem] z-10 my-10 mt-4 w-[225px] bg-lightBlue')}>
                                        <div className="date-container m-8">
                                            {monthArray2.map((date, index) => {
                                                const firstIndex = index === 0;
                                                const lastIndex = index === monthArray2.length - 1;
                                                const monthOnly = firstIndex ? 'This Month' : date.split(' ')[0];
                                                const newMonthDate = new Date(firstIndex ? currentDate : date);
                                                const newMonthLastDay = new Date(newMonthDate.getFullYear(), newMonthDate.getMonth() + 1, 0);
                                                return (
                                                    <div className="text-center" key={index}>
                                                        <button
                                                            onClick={() => changeStartDate(monthOnly, newMonthDate, !lastIndex && newMonthLastDay)}
                                                            style={{ borderWidth: '1px' }}
                                                            className={cx(
                                                                'gift-cta border-1 mb-4 w-[140px] whitespace-pre rounded-3xl border-white py-2 font-proxima font-normal transition duration-500 ease-in-out hover:bg-yellow hover:text-black dark:hover:text-black',
                                                                monthSelected === monthOnly ? 'bg-yellow text-black' : 'bg-transparent text-white'
                                                            )}>
                                                            {monthOnly} {lastIndex && '↓'}
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                            <div
                                                onClick={() => {
                                                    setMonthSelected('');
                                                    setStartandEndDate([currentDate.toLocaleDateString(), '']);
                                                }}
                                                className="mt-2 cursor-pointer text-white">
                                                Clear Month
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {(!attendEventsRows || attendEventsLoading) && <NoResults loading={attendEventsLoading} />}
                        <div className="flex flex-wrap justify-center">
                            {attendEventsRows?.length &&
                                !attendEventsLoading &&
                                attendEventsRows?.map((event, index) => {
                                    const {
                                        HOSTNAME,
                                        TYPE,
                                        SPEAKERNAME,
                                        MEETINGTTIME,
                                        MEETINGADDRESSBLOCK,
                                        MEETINGCITY,
                                        MEETINGSTATEABBREVIATION,
                                        MEETINGPOSTCODE,
                                        MEETINGPHONENUMBER
                                    } = event;
                                    let meetingDate;
                                    parseString(MEETINGTTIME[0], (err, result) => {
                                        meetingDate = result['MEETINGTIMES']['MEETINGTIME'];
                                    });
                                    const listofHarediNames = /David Liebman|Ofer Levy|Laura Barron|Karol Joseph|Shmuel Abramson/;
                                    let speakerName = SPEAKERNAME[0].replace(/\s[a-zA-Z]\./, '').replace(listofHarediNames, 'Jews for Jesus');

                                    const correctStaffMember = staffData?.allStaff?.nodes.find(staffMember => speakerName === staffMember.title);

                                    const imageUrl = correctStaffMember?.featuredImage?.node?.sourceUrl;
                                    const fiveDigitZipCode = MEETINGPOSTCODE[0].split('-');

                                    return (
                                        <div className="m-4 w-[250px] bg-white px-5 pb-7 pt-4 dark:bg-charcoal dark:text-white" key={index}>
                                            <Image
                                                src={imageUrl || '/images/placeholder-star.gif'}
                                                alt={speakerName}
                                                width="125"
                                                height="125"
                                                className="staff-image mx-auto mb-6 rounded-full"
                                            />
                                            <h3 className="text-xl">{TYPE[0]}</h3>
                                            <div className="mt-4">
                                                <div className="text-sm leading-tight">
                                                    Presented by <strong>{speakerName}</strong>
                                                    <DateTime meetingTimes={meetingDate} />
                                                </div>
                                                <p className="mt-4 text-sm">
                                                    <strong>{HOSTNAME[0]}</strong>
                                                    <br />
                                                    {MEETINGADDRESSBLOCK[0]}
                                                    <br />
                                                    {MEETINGCITY[0]}, {MEETINGSTATEABBREVIATION[0]} {fiveDigitZipCode[0]}
                                                    <br />
                                                    <a href={`tel:${MEETINGPHONENUMBER[0]}`}>{MEETINGPHONENUMBER[0]}</a>
                                                </p>
                                            </div>
                                            {(TYPE[0] === 'Christ in the Passover: An Interactive Experience' || TYPE[0] === 'Christ in the Passover: A Seder') && (
                                                <div className=" mt-2 text-[12px]">Please call the church if you wish to attend as registration may be required.</div>
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="my-8 text-center">
                            <a
                                className={page === 1 ? 'pointer-events-none text-gray dark:text-charcoal' : undefined}
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth'
                                    });
                                    setPage(currentPage => currentPage - 1);
                                }}>
                                Previous
                            </a>
                            <a
                                className={cx('ml-4', attendEventsData?.TotalAvailableRows <= 19 ? 'pointer-events-none text-gray dark:text-charcoal' : undefined)}
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth'
                                    });
                                    setPage(currentPage => currentPage + 1);
                                }}>
                                Next
                            </a>
                        </div>
                    </Container>
                </div>
                <Footer />
            </ClickHandler>
        </GlobalAppThemeProvider>
    );
}

export const getStaticProps = async function () {
    const apolloClient = initializeApollo();
    await apolloClient.query({ query: ThemeGeneralSettingsDocument });

    return addApolloState(apolloClient, {
        props: {},
        revalidate: 60
    });
};
